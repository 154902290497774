import { IConfigurationModel, IScreenModel } from "@nf/common";
import { SEO } from "../../components";
import { renderPageBody } from "../../helpers";

export interface LandingScreenProps {
  configuration?: IConfigurationModel;
  screen: IScreenModel;
}

const LandingScreen = ({ configuration, screen }: LandingScreenProps) => {
  if (!screen?.Components?.length) {
    return null;
  }

  return (
    <>
      <SEO screenKey="landingpage" configuration={configuration} />

      {configuration?.System?.CustomScript && (
        <div
          dangerouslySetInnerHTML={{
            __html: configuration.System.CustomScript,
          }}
        />
      )}

      {renderPageBody(screen)}
    </>
  );
};

export default LandingScreen;
